<template>
  <HorizontalItemsScroller
    loop
    :class="{
      'lg:col-start-5 lg:col-end-13 lg:mt-4': showParagraph,
      'col-start-1 col-end-13 mt-4': !showParagraph,
    }"
    @intersect="onIntersect"
    @change="onChange"
  >
    <ProductWrapper
      v-for="(product, index) in products"
      :key="`product-slider-item-${product.id}`"
      class="w-full shrink-0 xs:w-1/2 sm:w-1/3"
      :product="product"
      :fetching="fetching"
      @click:product="
        (clickedProduct: Product) =>
          $emit('click:product', { product: clickedProduct, index })
      "
      @intersect:product="
        (intersectedProduct: Product) =>
          $emit('intersect:product', { product: intersectedProduct, index })
      "
    />
  </HorizontalItemsScroller>
</template>

<script setup lang="ts">
import type { Product } from '@scayle/storefront-nuxt'

type Props = {
  productIds?: Array<Product['id']>
  productReferenceKeys?: Array<
    WithRequired<Product, 'referenceKey'>['referenceKey']
  >
  uid: string
  showParagraph: boolean
}

const emit = defineEmits<{
  (
    e: 'click:product' | 'intersect:product',
    value: { index: number; product: Product },
  ): void
  (
    e: 'change' | 'intersect',
    value: { index: number; perPage: number; products: Product[] },
  ): void
}>()

const props = defineProps<Props>()

const { data: productsById, status: statusById } = await useProductsByIds({
  params: {
    ids: props.productIds || [],
    with: SAFE_PRODUCT_WITH,
  },
  key: `productSlider-${props.uid}`,
  options: { dedupe: 'defer' },
})

const { data: productsByReferenceKey, status: statusByReferenceKey } =
  await useAsyncProductsByReferenceKey({
    params: {
      referenceKeys: props.productReferenceKeys || [],
      with: SAFE_PRODUCT_WITH,
    },
    options: { dedupe: 'defer' },
  })

const products = computed(() => {
  const uniqueReferenceKeys = [...new Set(props.productReferenceKeys)]
  const uniqueIds = [...new Set(props.productIds)]

  const result = uniqueReferenceKeys.flatMap((key) => {
    const p = productsByReferenceKey?.value?.find((p) => p.referenceKey === key)
    return p ? [p] : []
  })

  return [
    ...result,
    ...uniqueIds.flatMap((id) => {
      if (result.some((p) => p && p.id === id)) {
        return []
      }

      const p = productsById.value?.find((p) => p.id === id)

      return p ? [p] : []
    }),
  ].filter((product) => isProductValid(product))
})

const getProducts = (start: number, count: number) => {
  const result = products.value.slice(start, start + count)

  if (result.length < count && products.value.length >= count) {
    return [...result, ...products.value.slice(0, count - result.length)]
  }

  return result
}

const onChange = (data: { index: number; perPage: number }) => {
  emit('change', {
    index: data.index,
    perPage: data.perPage,
    products: getProducts(data.index, data.perPage),
  })
}

const onIntersect = (data: { index: number; perPage: number }) => {
  emit('intersect', {
    index: data.index,
    perPage: data.perPage,
    products: getProducts(data.index, data.perPage),
  })
}

const fetching = computed(() => {
  const readyStatus = ['success', 'error']
  return (
    !readyStatus.includes(statusById.value) ||
    !readyStatus.includes(statusByReferenceKey.value)
  )
})
</script>
